import React, { useState } from "react"
import { icons } from "../../../utils/icons";
import AllocationColorGrading from "./allocationColorGrading";
import FlightItemsColorCoding from "./flightItemsColorCoding";
import AllocationLabel from "./allocationLabel";
import Behaviour from "./behaviour";
import FlightItemsLabel from "./flightItemsLabel";

interface ViewSettingModalProp {
    onClose: () => void
}

const ViewSettingModal: React.FC<ViewSettingModalProp> = ({ onClose }) => {
    const [activeComponent, setActiveComponent] = useState('allocationColorGrading');

    const renderActiveComponent = () => {
        switch (activeComponent) {
            case 'flightItemsColorCoding':
                return <FlightItemsColorCoding />;
            case 'allocationLabel':
                return <AllocationLabel />;
            case 'flightItemsLabel':
                return <FlightItemsLabel />;
            case 'behaviour':
                return <Behaviour />;
            default:
                return <AllocationColorGrading />;
        }
    };

    return (
        <div
            id="static-modal"
            data-modal-backdrop="static"
            tabIndex={-1}
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-4xl">
                <div className="relative rounded-lg shadow-lg">
                    <div className="grid grid-rows-3 grid-cols-3 grid-flow-col min-h-[780px] rounded-lg">
                        <div className="row-span-3 bg-slate-200 rounded-l-lg p-2">
                            <button onClick={() => onClose()} className="flex items-center space-x-2 text-sm font-medium pb-4">
                                <icons.PI.PiArrowLeftLight size={20} />
                                <span className="font-bold text-md">Back</span>
                            </button>
                            <div className='p-2 text-sm'>
                                <span className='text-gray-400'>Color Legend</span>
                                <button className={`flex items-center space-x-1 px-2 py-1 text-gray-700 w-full ${activeComponent === 'allocationColorGrading' ? 'shadow-md bg-white rounded' : ''}`} onClick={() => setActiveComponent('allocationColorGrading')}>
                                    <icons.PI.PiGradientLight />
                                    <span>Allocation Color Grading</span>
                                </button>
                                <button className={`flex items-center space-x-1 px-2 py-1 text-gray-700 w-full ${activeComponent === 'flightItemsColorCoding' ? 'shadow-md bg-white rounded' : ''}`} onClick={() => setActiveComponent('flightItemsColorCoding')}>
                                    <icons.PI.PiAirplaneInFlightLight />
                                    <span>Flight Items Color Coding</span>
                                </button>
                            </div>


                            <div className='p-2 text-gray-400 text-sm'>
                                Label View Configuration
                                <button className={`flex items-center space-x-1 px-2 py-1 text-gray-700 w-full ${activeComponent === 'allocationLabel' ? 'shadow-md bg-white rounded' : ''}`} onClick={() => setActiveComponent('allocationLabel')}>
                                    <icons.PI.PiPlaceholderLight />
                                    <span>Allocation Label</span>
                                </button>
                                <button className={`flex items-center space-x-1 px-2 py-1 text-gray-700 w-full ${activeComponent === 'flightItemsLabel' ? 'shadow-md bg-white rounded' : ''}`} onClick={() => setActiveComponent('flightItemsLabel')}>
                                    <icons.PI.PiAirplaneInFlightLight />
                                    <span>Flight Items Label</span>
                                </button>
                            </div>

                            <div className='p-2 text-sm'>
                                <p className='text-gray-400'>Allocation Layering</p>
                                <button className={`flex items-center space-x-1 px-2 py-1 text-gray-700 w-full ${activeComponent === 'behaviour' ? 'shadow-md bg-white rounded' : ''}`} onClick={() => setActiveComponent('behaviour')}>
                                    <icons.PI.PiChartBarHorizontalLight />
                                    <span>Behaviour</span>
                                </button>
                            </div>

                        </div>
                        <div className="row-span-3 col-span-2 p-4 bg-white rounded-r-lg">
                            <p className='font-bold text-lg'>View Configuration</p>
                            <small className='text-xs text-gray-400'>Change how individual nodes are colored and labelled in the Gantt Chart</small>
                            <div className='py-2'>

                                {renderActiveComponent()}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewSettingModal