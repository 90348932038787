import React, { useState } from "react";
import { icons } from "../../../utils/icons";

const FlightItemsColorCoding = () => {
    const [configs, setConfigs] = useState([
        {
            name: "Early(Node)",
            description: 'For either early inbound or early outbound flights',
            color: '#FFA07A' // Light Salmon
        },
        {
            name: "Early(Marker)",
            description: 'Denotation marker for time sensitive information',
            color: '#FF4500' // Orange Red
        },
        {
            name: "Late(Node)",
            description: 'Late inbound flight or late outbound flights',
            color: '#6495ED' // Cornflower Blue
        },
        {
            name: "Late(Marker)",
            description: 'Denotation marker for time sensitive information',
            color: '#4682B4' // Steel Blue
        },
        {
            name: "Cancelled",
            description: 'Flight that were cancelled by either operators or other operations',
            color: '#DC143C' // Crimson
        },
        {
            name: "On Time",
            description: 'Flight that are in-line with scheduled OTEs',
            color: '#32CD32' // Lime Green
        },
        {
            name: "Scheduled",
            description: 'Scheduled flights that have not arrived not departed',
            color: '#FFD700' // Gold
        },
        {
            name: "Undefined",
            description: 'Flight items that are scheduled that have an undefined status',
            color: '#808080' // Gray
        },
    ]);


    const [OTEs, setOTEs] = useState([
        {
            name: "Scheduled",
            description: 'Flight that are not ongoing',
            color: '#FFA07A' // Light Salmon
        },
        {
            name: "Completed",
            description: 'Flight times that have finished operations',
            color: '#FF4500' // Orange Red
        },
        {
            name: "Ongoing",
            description: 'Flight times that are currently in operation',
            color: '#6495ED' // Cornflower Blue
        }
    ]);

    const handleChangeConfigs = (index: number, newColor: string) => {
        setConfigs((prevConfigs) => {
            const updatedConfigs = [...prevConfigs];
            updatedConfigs[index].color = newColor;
            return updatedConfigs;
        });
    };

    const handleChangeOTEs = (index: number, newColor: string) => {
        setOTEs((prevConfigs) => {
            const updatedConfigs = [...prevConfigs];
            updatedConfigs[index].color = newColor;
            return updatedConfigs;
        });
    };

    return (
        <div>
            <div className="p-2 mt-2">
                <div className="flex items-center gap-x-2 border-b">
                    <icons.PI.PiToggleRightFill size={24} className="text-green-400" />
                    <span className="font-bold text-sm">Status</span>
                </div>
                <div>
                    {configs.map((config, index) => (
                        <div
                            key={index}
                            className="flex justify-between items-center border-b mt-4"
                        >
                            <div>
                                <p className="font-bold text-sm">{config.name}</p>
                                <p className="text-xs text-gray-400">{config.description}</p>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <div className="relative">
                                    <icons.MD.MdColorLens
                                        size={24}
                                        className="text-gray-500 cursor-pointer"
                                    />
                                    <input
                                        type="color"
                                        value={config.color}
                                        onChange={(e) => handleChangeConfigs(index, e.target.value)}
                                        className="absolute inset-0 opacity-0 cursor-pointer"
                                    />
                                </div>
                                <div
                                    className="rounded h-5 w-5 border"
                                    style={{ backgroundColor: config.color }}
                                ></div>
                                <p className="text-gray-400">{config.color}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="p-2 mt-2">
                <div className="flex items-center gap-x-2 border-b">
                    <icons.PI.PiToggleRightFill size={24} className="text-green-400" />
                    <span className="font-bold text-sm">OTE (Operation Time Metrics)</span>
                </div>
                <div>
                    {OTEs.map((config, index) => (
                        <div
                            key={index}
                            className="flex justify-between items-center border-b mt-4"
                        >
                            <div>
                                <p className="font-bold text-sm">{config.name}</p>
                                <p className="text-xs text-gray-400">{config.description}</p>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <div className="relative">
                                    <icons.MD.MdColorLens
                                        size={24}
                                        className="text-gray-500 cursor-pointer"
                                    />
                                    <input
                                        type="color"
                                        value={config.color}
                                        onChange={(e) => handleChangeOTEs(index, e.target.value)}
                                        className="absolute inset-0 opacity-0 cursor-pointer"
                                    />
                                </div>
                                <div
                                    className="rounded h-5 w-5 border"
                                    style={{ backgroundColor: config.color }}
                                ></div>
                                <p className="text-gray-400">{config.color}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default FlightItemsColorCoding;
