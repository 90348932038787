import GateLayout from "./gateRule/gateLayout"

const GateRuleEditor = () => {
    return (
        <div>
            <GateLayout />
        </div>
    )
}

export default GateRuleEditor