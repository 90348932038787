import React, { useEffect, useState } from 'react';
import { icons } from '../../../../utils/icons';

interface DayListProps {
  onDateChange: (date: Date) => void; // Callback function to notify parent
  currentDayInView: Date | null; // The current day in view passed from the parent
}

const DayList: React.FC<DayListProps> = ({ onDateChange, currentDayInView }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const today = new Date();

  const DayLabel = (dayLabel: string | any[]) => {
    // Split the label into alphabet and number
    const alphabet = dayLabel[0]; // Extract the first character
    const number = dayLabel.slice(1); // Extract the rest

    return (
      <div className="flex items-center">
        <span className="font-bold text-blue-500">{alphabet}</span> {/* Style for the alphabet */}
        <span className="font-normal text-gray-700">{number}</span> {/* Style for the number */}
      </div>
    );
  };

  // Recalculate the days when the currentDate changes
  const getDaysOfMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysArray = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const dayDate = new Date(year, month, day);
      const dayAbbreviation = dayDate.toLocaleDateString('en-US', { weekday: 'short' }).charAt(0);
      daysArray.push({
        dayLabel: `${dayAbbreviation}${String(day).padStart(2, '0')}`,
        fullDate: dayDate,
        isToday:
          dayDate.getDate() === today.getDate() &&
          dayDate.getMonth() === today.getMonth() &&
          dayDate.getFullYear() === today.getFullYear(),
      });
    }

    return daysArray;
  };

  // Handle month navigation
  const handlePrevious = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    setCurrentDate(newDate);
    onDateChange(newDate); // Notify parent
  };

  const handleNext = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    setCurrentDate(newDate);
    onDateChange(newDate); // Notify parent
  };

  const handleDayClick = (day: Date) => {
    setCurrentDate(day);
    onDateChange(day); // Notify parent
  };

  // Sync the currentDate with currentDayInView when it changes
  useEffect(() => {
    if (currentDayInView && currentDayInView.getMonth() !== currentDate.getMonth()) {
      setCurrentDate(new Date(currentDayInView)); // Update the month to match the parent
    }
  }, [currentDayInView]);

  const days = getDaysOfMonth(currentDate);

  return (
    <div className="w-full py-2">
      <div className="flex items-center justify-between">
        <button
          onClick={handlePrevious}
          className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300"        >
          <icons.PI.PiArrowCircleLeft size={15} className='me-2' />
          Previous
        </button>
        <div className="flex-1 flex justify-evenly items-center">
          {days.map((day, index) => (
            <button
              key={index}
              onClick={() => handleDayClick(day.fullDate)}
              className={`text-sm px-2 py-1 rounded ${day.isToday ? 'bg-gradient-to-t from-blue-400 to-indigo-100 border-b border-blue-900 text-white' : 'text-gray-700'}
              ${currentDate.toDateString() === day.fullDate.toDateString() ? 'bg-gradient-to-t from-blue-300 to-indigo-100' : ''}
              ${currentDayInView && currentDayInView.toDateString() === day.fullDate.toDateString() ? 'bg-gradient-to-t from-slate-300 to-gray-100' : ''} hover:bg-gray-300`}
            >{day.dayLabel}
            </button>
          ))}
        </div>
        <button
          onClick={handleNext}
          className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300"        >
          Next
          <icons.PI.PiArrowCircleRight size={15} className='ms-2' />
        </button>
      </div>
    </div>
  );
};

export default DayList;
