import TabsLayout from "../components/tabsLayout"

const ResourceManagement = () => {
    return (
        <div className="dashboard-container px-2 flex flex-col h-full w-full">
            <h1 className="text-xl font-bold mb-0">Gate Allocation System</h1>
            <TabsLayout />
        </div>
    )
}

export default ResourceManagement