import { icons } from "../../../utils/icons"

const AllocationLabel = () => {
    return (
        <div>
            <div className="flex justify-between p-2 border-b">
                {/* Each div for the label and icon */}
                <div className="flex items-center gap-x-2">
                    <icons.PI.PiToggleRightFill size={24} className="text-green-400" />
                    <span className="font-bold text-sm">Left</span>
                </div>
                <div className="flex items-center gap-x-2">
                    <icons.PI.PiToggleRightFill size={24} className="text-green-400" />
                    <span className="font-bold text-sm">Middle</span>
                </div>
                <div className="flex items-center gap-x-2">
                    <icons.PI.PiToggleRightFill size={24} className="text-green-400" />
                    <span className="font-bold text-sm">Right</span>
                </div>
            </div>

            <div className="bg-gray-200 p-4 mt-4">
                <p className="font-bold text-lg">Left/Right Stand Allocation</p>
                <p className="text-xs">Smaller allocation nodes will only display 2 of the topmost labels selected</p>
            </div>
        </div>
    )
}

export default AllocationLabel
