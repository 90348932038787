import GateHeader from "./gateHeader"
import RuleList from "./ruleList"

const GateLayout = () => {
    return (
        <div>
            <GateHeader />
            <RuleList />
        </div>
    )
}

export default GateLayout