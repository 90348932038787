import React, { useEffect, useRef, useState } from 'react';
import { icons } from '../../../../utils/icons';
import { activeFlight, assignFlight, datas, flights } from '../../../../utils/sharedConst';
import FlightDetail from '../flightDetail';

interface HourLinesProps {
    selectedDate: Date;
    rowHeight: number;
    onDayInViewChange: (day: Date) => void;
}

const HourLines: React.FC<HourLinesProps> = ({ selectedDate, onDayInViewChange, rowHeight }) => {
    const topScrollRef = useRef<HTMLDivElement>(null);
    const bottomScrollRef = useRef<HTMLDivElement>(null);
    const [isSyncing, setIsSyncing] = useState(false);
    const [isFlightDetail, setIsFlightDetail] = useState(false);
    const [isSelectedFlight, setIsSelectedFlight] = useState()
    const [scrollWidth, setScrollWidth] = useState(0);

    const hourBlockWidth = 384;

    const setActiveFlight = (flight: any) => {
        setIsSelectedFlight(flight)
        setIsFlightDetail(true)
    }
    const getRectangleStyle = (
        start: number,
        duration: number,
        color: string,
        row: number,
        type: string,
        isFirst: boolean,
        isLast: boolean
    ): React.CSSProperties => {
        const topPosition = (row - 1) * rowHeight;

        const baseStyle: React.CSSProperties = {
            left: (start / 60) * hourBlockWidth,
            width: (duration / 60) * hourBlockWidth,
            height: `${rowHeight}px`,
            top: `${topPosition}px`,
            backgroundColor: color,
            opacity: 0.5,
            position: 'absolute',
            clipPath: 'none', // Default: plain rectangle
        };

        // Apply the pencil shape conditionally
        if (type === 'inbound' && isFirst) {
            baseStyle.clipPath = 'polygon(5% 0%, 100% 0%, 100% 100%, 5% 100%, 0% 50%)'; // Sharp side left
        } else if (type === 'outbound' && isLast) {
            baseStyle.clipPath = 'polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%)'; // Sharp side right
        }

        return baseStyle;
    };

    const getOverlappingAssignFlight = (hour: { date: Date }) => {
        return assignFlight.filter((rect) => {
            const sameDay =
                rect.start.toDateString() === hour.date.toDateString(); // Match the date
            const overlapsTime =
                rect.start.getTime() < hour.date.getTime() + 3600000 && // Ends after hour starts
                rect.end.getTime() > hour.date.getTime(); // Starts before hour ends

            return sameDay && overlapsTime;
        });
    };

    const getOverlappingActiveFlight = (hour: { date: Date }) => {
        return activeFlight.filter((rect) => {
            const sameDay =
                rect.start.toDateString() === hour.date.toDateString(); // Match the date
            const overlapsTime =
                rect.start.getTime() < hour.date.getTime() + 3600000 && // Ends after hour starts
                rect.end.getTime() > hour.date.getTime(); // Starts before hour ends

            return sameDay && overlapsTime;
        });
    };


    const generateHours = (baseDate: Date) => {
        const days = [1]; // Current day only (can extend as needed)
        const result: { label: string; date: Date }[] = [];

        days.forEach((offset) => {
            const date = new Date(baseDate);
            date.setDate(date.getDate() + offset);
            for (let hour = 0; hour < 24; hour++) {
                result.push({
                    label: `${String(hour).padStart(2, '0')}:00`,
                    date: new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour),
                });
            }
        });

        return result;
    };

    const [visibleHours, setVisibleHours] = useState(() => generateHours(selectedDate));

    const generateHoursForDay = (baseDate: Date) => {
        const result: { label: string; date: Date }[] = [];
        for (let hour = 0; hour < 24; hour++) {
            const hourDate = new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), hour);
            result.push({
                label: `${String(hour).padStart(2, '0')}:00`,
                date: hourDate,
            });
        }
        return result;
    };

    useEffect(() => {
        setVisibleHours(generateHours(selectedDate));
    }, [selectedDate]);

    useEffect(() => {
        if (topScrollRef.current) {
            setScrollWidth(topScrollRef.current.scrollWidth);
        }
    }, [topScrollRef, visibleHours]);

    useEffect(() => {
        const container = topScrollRef.current;
        if (!container) return;

        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [visibleHours]);

    const handleScroll = () => {
        if (!topScrollRef.current) return;
        const { scrollLeft, clientWidth, scrollWidth } = topScrollRef.current;

        const getCurrentDayInView = () => {
            const index = Math.floor(scrollLeft / 384);
            if (visibleHours[index]) {
                const currentDay = visibleHours[index].date;
                onDayInViewChange(currentDay);
            }
        };

        getCurrentDayInView();

        if (scrollLeft < clientWidth) {
            const firstHourDate = visibleHours[0].date;
            const previousDay = new Date(firstHourDate);
            previousDay.setDate(firstHourDate.getDate() - 1);
            const previousDayHours = generateHoursForDay(previousDay);
            setVisibleHours((prev) => [...previousDayHours, ...prev]);
            topScrollRef.current.scrollLeft += 24 * 384;
        } else if (scrollLeft + clientWidth > scrollWidth - clientWidth) {
            const lastHourDate = visibleHours[visibleHours.length - 1].date;
            const nextDay = new Date(lastHourDate);
            nextDay.setDate(lastHourDate.getDate() + 1);
            const nextDayHours = generateHoursForDay(nextDay);
            setVisibleHours((prev) => [...prev, ...nextDayHours]);
        }
    };

    const handleSyncScroll = (source: 'top' | 'bottom') => {
        if (isSyncing) return;

        setIsSyncing(true);
        const sourceRef = source === 'top' ? topScrollRef : bottomScrollRef;
        const targetRef = source === 'top' ? bottomScrollRef : topScrollRef;

        if (sourceRef.current && targetRef.current) {
            targetRef.current.scrollLeft = sourceRef.current.scrollLeft;
        }

        setTimeout(() => setIsSyncing(false), 0); // Reset sync flag after the frame.
    };

    return (
        <div className='h-full relative'>
            {/* First ROW */}
            <div className="grid grid-cols-8">
                {/* Static Table */}
                <div className="pt-5 bg-slate-100">
                    {datas.slice(0, datas.length).map((data, index) => (
                        <div
                            key={index}
                            className="border-b flex items-center bg-white font-semibold p-2"
                            style={{ height: `${rowHeight}px` }}
                        >
                            {data}
                        </div>
                    ))}
                </div>

                {/* HourLines */}
                <div className="bg-slate-100 col-span-7">
                    <div ref={topScrollRef} onScroll={() => handleSyncScroll('top')} className="relative w-full h-full overflow-x-scroll">
                        {/* Rows overlay */}
                        <div
                            className="absolute top-5 left-0 pointer-events-none"
                            style={{ width: scrollWidth, height: `${datas.length * rowHeight}px` }}
                        >
                            {Array.from({ length: datas.length }).map((_, index) => (
                                <div
                                    key={index}
                                    className="absolute w-full border-b border-gray-300"
                                    style={{
                                        top: `${index * rowHeight}px`,
                                        height: `${rowHeight}px`,
                                    }}
                                ></div>
                            ))}
                        </div>

                        {/* Main content */}
                        {visibleHours && <div className="relative flex h-full">
                            {visibleHours.map((hour, index) => (
                                <div
                                    key={index}
                                    className="relative flex flex-col items-center"
                                    style={{ minWidth: "384px" }}
                                >
                                    {/* Hour label */}
                                    <div className="text-gray-400 font-semibold text-sm ">
                                        {hour.label}
                                    </div>
                                    {/* Vertical line */}
                                    <div className="h-full border-r border-gray-400 relative">
                                        {getOverlappingAssignFlight(hour).map((rect, rectIndex) => {
                                            const startMinutes = rect.start.getTime() > hour.date.getTime() ? rect.start.getMinutes() : 0;
                                            const endMinutes = rect.end.getTime() < hour.date.getTime() + 3600000 ? rect.end.getMinutes() : 60;
                                            const duration = endMinutes - startMinutes;

                                            return (
                                                <div
                                                    key={rectIndex}
                                                ></div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>}

                    </div>
                </div>
            </div>

            <div className="border border-gray-500 w-full my-2"></div>

            {/* SECOND ROW */}
            <div className="grid grid-cols-8" style={{ height: `${rowHeight * flights.length}px` }}>
                <div className="flex flex-col items-center justify-center space-y-2 bg-slate-100">
                    {/* Static Button */}
                    <button type="button" className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300">
                        <icons.PI.PiPlugsConnectedLight className='me-2' />
                        Link Flights
                    </button>

                    <button type="button" className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300">
                        <icons.PI.PiPlusLight className='me-2' />
                        Assign Flight
                    </button>
                </div>

                {/* HourLines */}
                <div
                    className="bg-blue-300 col-span-7">
                    <div ref={bottomScrollRef}
                        onScroll={() => handleSyncScroll('bottom')} className="relative w-full h-full overflow-x-scroll bg-gray-100">
                        {/* Rows overlay */}
                        <div
                            className="absolute left-0 pointer-events-none"
                            style={{ width: scrollWidth, height: `${flights.length * rowHeight}px` }}
                        >
                            {Array.from({ length: flights.length }).map((_, index) => (
                                <div
                                    key={index}
                                    className="absolute w-full border-b border-gray-300"
                                    style={{
                                        top: `${index * rowHeight}px`,
                                        height: `${rowHeight}px`,
                                    }}
                                ></div>
                            ))}
                        </div>

                        {/* Main content */}
                        <div className="relative flex h-full">
                            {visibleHours.map((hour, index) => (
                                <div
                                    key={index}
                                    className="relative flex flex-col items-center"
                                    style={{ minWidth: "384px" }}
                                >
                                    <div className="h-full border-r border-gray-400 relative">
                                        {getOverlappingActiveFlight(hour).map((rect, rectIndex) => {
                                            const startMinutes = rect.start.getTime() > hour.date.getTime() ? rect.start.getMinutes() : 0;
                                            const endMinutes = rect.end.getTime() < hour.date.getTime() + 3600000 ? rect.end.getMinutes() : 60;
                                            const duration = endMinutes - startMinutes;

                                            const isFirst = rect.start.getTime() >= hour.date.getTime() && rect.start.getTime() < hour.date.getTime() + 3600000;
                                            const isLast = rect.end.getTime() > hour.date.getTime() && rect.end.getTime() <= hour.date.getTime() + 3600000;

                                            return (
                                                <button
                                                    onClick={() => { setActiveFlight(rect); }}
                                                    key={rectIndex}
                                                    style={getRectangleStyle(startMinutes, duration, rect.color, rect.row, rect.type, isFirst, isLast)}
                                                >
                                                </button>
                                            );
                                        })}



                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`fixed top-0 right-0 h-screen pb-2 pr-2 shadow-xl transition-all duration-300 ease-in-out transform ${isFlightDetail && isSelectedFlight ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full pointer-events-none'
                    }`}
            >
                <FlightDetail activeFlight={isSelectedFlight} onClose={() => setIsFlightDetail(false)} />
            </div>


        </div>
    );
};

export default HourLines;
