import { icons } from "../../../../utils/icons"

const RuleList = () => {
    return (
        <div className="flex text-4xl text-gray-400 mt-72 items-center justify-center text-center">
            <icons.PI.PiExclamationMarkDuotone /> <p className="ml-2">No Rule Displayed</p>
        </div>
    )
}

export default RuleList