import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import { icons } from '../../../utils/icons';
import FlightDetailLog from './flightDetailLog';

const ActiveFlightTable = () => {
    const [activeTab, setActiveTab] = useState('all');
    const [data, setData] = useState<any[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isUTC, setIsUTC] = useState(true);
    const [isFlightDetail, setIsFlightDetail] = useState(false)
    const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});

    const remark = [
        'LANDED',
        'DEPARTED',
        'DELAY',
        'GATE OPEN'
    ]

    const fetchFlightData = async (tripType: string) => {
        const url = `https://pa.processor.industronicsadvansystem.com/flight-trips?displayOffset=20&bagMovementMins=5&tripType=${tripType}`;
        try {
            const response = await fetch(url);
            const flightData = await response.json(); // Parse JSON response
            setData(flightData);
            setIsFetched(true);
        } catch (error) {
            console.error("Error fetching flight data:", error);
        }
    };

    useEffect(() => {
        let tripType = 'Departure';
        if (activeTab === 'departure') {
            tripType = 'Departure';
        } else if (activeTab === 'arrival') {
            tripType = 'Arrival';
        }
        fetchFlightData(tripType);
    }, [activeTab]);

    // Utility function to format date
    const formatDate = (dateString: string, isUTC: boolean) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hourCycle: 'h23',
            timeZone: isUTC ? 'UTC' : 'Asia/Kuala_Lumpur'
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'flightNumber',
                enableClickToCopy: true,
                header: 'Flight No.',
                enableEditing: false,
                size: 50,
            },
            {
                accessorKey: 'stations',
                header: 'Station',
                enableEditing: false,
                size: 50,
                Cell: ({ cell }) => {
                    // Get the array of station objects from the cell value
                    const stations = cell.getValue<{ airport: string; stationNumber: number }[]>();

                    // Map over stations to get the airports, join them as a string
                    const airportList = stations
                        ? stations.map(station => station.airport).join(' | ')
                        : 'N/A';

                    return (
                        <span>{airportList}</span>
                    );
                }
            },
            {
                accessorKey: 'scheduledTravelTime',
                header: 'STAD',
                enableEditing: false,
                size: 50,
                Cell: ({ cell }) => {
                    const timestamp = cell.getValue<string>();
                    const formattedTimestamp = timestamp ? formatDate(timestamp, isUTC) : 'N/A';
                    return (
                        <span>{formattedTimestamp}</span>
                    );
                }
            },
            {
                accessorKey: 'ETA',
                header: 'ETAD',
                enableEditing: false,
                size: 50,
                Cell: ({ cell }) => {
                    const timestamp = cell.getValue<string>();
                    const formattedTimestamp = timestamp ? formatDate(timestamp, isUTC) : 'N/A';
                    return (
                        <span>{formattedTimestamp}</span>
                    );
                }
            },
            {
                accessorKey: 'actualArrivalDepartureTime',
                header: 'ATAD',
                enableEditing: false,
                size: 50,
                Cell: ({ cell }) => {
                    const timestamp = cell.getValue<string>();
                    const formattedTimestamp = timestamp ? formatDate(timestamp, isUTC) : 'N/A';
                    return (
                        <span>{formattedTimestamp}</span>
                    );
                }
            },
            {
                accessorKey: 'codeShareFlight',
                header: 'Code Share',
                enableEditing: false,
                size: 50,
                Cell: ({ cell }) => {
                    const codeShareFlights = cell.getValue<string[]>();
                    const [currentIndex, setCurrentIndex] = useState(0);

                    // Set up interval to rotate through the array
                    useEffect(() => {
                        if (codeShareFlights && codeShareFlights.length > 1) {
                            const intervalId = setInterval(() => {
                                setCurrentIndex((prevIndex) =>
                                    prevIndex === codeShareFlights.length - 1 ? 0 : prevIndex + 1
                                );
                            }, 1500); // Change every 2 seconds

                            // Clear the interval on component unmount
                            return () => clearInterval(intervalId);
                        }
                    }, [codeShareFlights]);

                    return (
                        <div>
                            {codeShareFlights && codeShareFlights.length > 0 ? `${codeShareFlights[currentIndex]} , ${currentIndex + 1}/${codeShareFlights.length}` : '-'}
                        </div>
                    );
                }
            },
            {
                accessorKey: 'aircraftRegnNo',
                header: 'A/C Reg No',
                enableEditing: false,
                size: 50,
            },
            {
                accessorKey: 'aircraftType',
                header: 'A/C Type',
                enableEditing: false,
                size: 50,
            },
            {
                accessorKey: 'standCode',
                header: 'Stand',
                enableEditing: false,
                size: 50,
            },
            {
                accessorKey: 'remarkCode.description',
                header: 'Remark',
                size: 50,
                editVariant: 'select',
                editSelectOptions: remark,
                muiEditTextFieldProps: ({ row }) => ({
                    select: true,
                    error: !!validationErrors?.state,
                    helperText: validationErrors?.state,
                }),
            },
        ],
        [isUTC]
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnOrdering: true,
        enableRowActions: true,
        enableStickyHeader: true,
        editDisplayMode: 'cell',
        enableCellActions: false,
        enableColumnPinning: true,
        enableEditing: true,
        positionActionsColumn: 'last',
        initialState: {
            columnPinning: {
                left: ['mrt-row-expand', 'mrt-row-select'],
                right: ['mrt-row-actions'],
            },
        },
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            rowsPerPageOptions: [10, 20, 30],
        },
        renderRowActionMenuItems: ({ closeMenu }) => [
            <div className='mx-2' key={0 + 1}>
                <button onClick={() => setIsFlightDetail(true)}>
                    <icons.MD.MdHistory size={23} className='inline' />
                    <div className='inline ml-2'>Detail</div>
                </button>
            </div>
        ],
    });

    const LoadingSkeleton = ({ rows = 8 }) => (
        <div role="status" className="max-w-full p-4 space-y-4 animate-pulse">
            {[...Array(rows)].map((_, index) => (
                <div key={index} className="flex items-center justify-between">
                    <div>
                        <div className="h-2.5 w-24 bg-gray-300 rounded-full mb-2.5"></div>
                        <div className="w-32 h-2 bg-gray-200 rounded-full"></div>
                    </div>
                    <div className="h-2.5 w-12 bg-gray-300 rounded-full"></div>
                </div>
            ))}
            <span className="sr-only">Loading...</span>
        </div>
    );

    return (
        <div className="dashboard-container px-2 flex flex-col h-full w-full">
            <h1 className="text-xl font-bold mb-2">Active Flight</h1>
            {/* Tab navigation */}
            <div className="border-b">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-gray-500">
                    <li className="mr-2">
                        <button
                            onClick={() => setActiveTab('all')}
                            className={`inline-flex items-center p-4 border-b-2 ${activeTab === 'all' ? 'border-blue-600 text-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'}`}
                        >
                            <icons.MD.MdOutlineConnectingAirports size={23} />
                            <span className="ml-3">All</span>
                        </button>
                    </li>
                    <li className="mr-2">
                        <button
                            onClick={() => setActiveTab('departure')}
                            className={`inline-flex items-center p-4 border-b-2 ${activeTab === 'departure' ? 'border-blue-600 text-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'}`}
                        >
                            <icons.MD.MdFlightTakeoff size={20} />
                            <span className="ml-3">Departure</span>
                        </button>
                    </li>
                    <li className="mr-2">
                        <button
                            onClick={() => setActiveTab('arrival')}
                            className={`inline-flex items-center p-4 border-b-2 ${activeTab === 'arrival' ? 'border-blue-600 text-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'}`}
                        >
                            <icons.MD.MdFlightLand size={20} />
                            <span className="ml-3">Arrival</span>
                        </button>
                    </li>
                </ul>
            </div>
            {/* Table container */}
            <div className="mx-2 overflow-x-auto">
                {isFetched ? (
                    <MaterialReactTable table={table} />
                ) : (
                    <div role="status" className="max-w-full p-4 space-y-4 animate-pulse">
                        <LoadingSkeleton rows={10} />
                    </div>
                )}
            </div>

            {isFlightDetail && <FlightDetailLog onClose={() => { setIsFlightDetail(false) }} />}


        </div>
    );
};

export default ActiveFlightTable;
