import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { useEffect, useState } from 'react';
import { icons } from '../../../utils/icons';
import ViewSettingModal from './viewSettingModal';
import DayList from './timeline/dayList';
import HourLines from './timeline/hourLine';

const Resources = () => {
    const [viewSetting, setViewSetting] = useState(false);

    useEffect(() => {
        flatpickr('#datepicker-range-start', {
            dateFormat: 'Y-m-d',
        });

        flatpickr('#datepicker-range-end', {
            dateFormat: 'Y-m-d',
        });
    }, []);

    const rowHeight = 40; // Set row height in pixels

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentDayInView, setCurrentDayInView] = useState<Date | null>(null); // Initialize as null
    const [currentMonthYear, setCurrentMonthYear] = useState<Date>(selectedDate); // Initialize as null

    const handleDateChange = (date: Date) => {
        setCurrentMonthYear(date)
        setSelectedDate(date); // Update the selected date in the parent
    };

    const handleDayInViewChange = (day: Date) => {
        setCurrentMonthYear(day)
        setCurrentDayInView(day); // Update the current day in view
    };

    return (
        <div>
            <div className="flex justify-between w-full border-b pb-1">
                <div className="flex items-center">
                    <button type="button" className="text-white bg-gray-800 hover:bg-gray-900 font-medium rounded-lg text-xs px-5 py-2 me-2 mb-0">Stand View</button>
                    <button type="button" className="text-white bg-gray-800 hover:bg-gray-900 font-medium rounded-lg text-xs px-5 py-2 me-2 mb-0">Check-In View</button>
                    <button type="button" className="text-white bg-gray-800 hover:bg-gray-900 font-medium rounded-lg text-xs px-5 py-2 me-2 mb-0">Gate View</button>
                </div>

                <div>
                    <h1 className="text-center text-md font-semibold">
                        {currentMonthYear.toLocaleDateString('en-US', {
                            month: 'long',
                            year: 'numeric',
                        })}
                    </h1>
                </div>

                <div className="flex items-center justify-center gap-x-2">
                    <button
                        type="button"
                        onClick={() => setViewSetting(true)}
                        className="text-white bg-blue-600 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-xs px-5 py-2 me-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                        View Setting
                    </button>

                    <form className="max-w-md mx-auto min-w-80">
                        <label
                            htmlFor="default-search"
                            className="mb-2 text-xs font-medium text-gray-900 sr-only dark:text-white"
                        >
                            Search
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <icons.PI.PiMagnifyingGlassThin />
                            </div>
                            <input
                                type="search"
                                id="default-search"
                                className="shadow-lg block w-full pl-10 p-2 text-xs text-gray-900 border border-gray-300 rounded-xl bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Search airline, status, gate number, destionation..."
                                required
                            />
                        </div>
                    </form>

                    <button type="button" className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300">
                        <icons.PI.PiFunnelThin className='me-2' />
                        Filter
                    </button>

                    <div id="date-range-picker" date-rangepicker className="flex items-center">
                        <div className="relative max-w-32">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <icons.PI.PiCalendarDuotone className='text-gray-500' size={20} />
                            </div>
                            <input
                                id="datepicker-range-start"
                                name="start"
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Date start"
                            />
                        </div>
                        <span className="mx-2 text-gray-500 text-xs">to</span>
                        <div className="relative max-w-32">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <icons.PI.PiCalendarDuotone className='text-gray-500' size={20} />
                            </div>
                            <input
                                id="datepicker-range-end"
                                name="end"
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Date end"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full h-full">
                <DayList
                    onDateChange={handleDateChange}
                    currentDayInView={currentDayInView}
                />
                <HourLines
                    selectedDate={selectedDate}
                    rowHeight={rowHeight}
                    onDayInViewChange={handleDayInViewChange}
                />
            </div>
            {viewSetting && <ViewSettingModal onClose={() => setViewSetting(false)} />}
        </div>

    )
}

export default Resources