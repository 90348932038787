import { useState } from "react";
import { icons } from "../../../utils/icons";
import GateAllocation from "./gateAllocation";
import Resources from "./resources";
import Aircraft from "./aircraft";
import GateRuleEditor from "./gateRuleEditor";

const tabs = [
    {
        id: "gateAllocation",
        label: "Gate Allocation",
        icon: <icons.PI.PiCaretDoubleRightThin size={20} />,
    },
    {
        id: "resources",
        label: "Resources",
        icon: <icons.PI.PiDatabaseThin size={20} />,
    },
    {
        id: "aircraft",
        label: "Aircraft",
        icon: <icons.PI.PiAirplaneTiltThin size={20} />,
    },
    {
        id: "gateRuleEditor",
        label: "Gate Rule Editor",
        icon: <icons.PI.PiHandTapThin size={20} />,
    },
];

const TabsLayout = () => {
    const [activeTab, setActiveTab] = useState('resources');

    const renderContent = () => {
        switch (activeTab) {
            case "resources":
                return <Resources />;
            case "aircraft":
                return <Aircraft />;
            case "gateRuleEditor":
                return <GateRuleEditor />;
            case "gateAllocation":
            default:
                return <GateAllocation />;
        }
    };

    return (
        <div>
            <div className="border-b">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-gray-500">
                    {tabs.map((tab) => (
                        <li key={tab.id} className="mr-2">
                            <button
                                onClick={() => setActiveTab(tab.id)}
                                className={`inline-flex items-center p-4 border-b-2 ${activeTab === tab.id
                                    ? "border-blue-600 text-blue-600"
                                    : "border-transparent hover:text-gray-600 hover:border-gray-300"
                                    }`}
                            >
                                {tab.icon}
                                <span className="ml-3 text-sm">{tab.label}</span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="py-2">
                {renderContent()}
            </div>

        </div>
    )
}

export default TabsLayout