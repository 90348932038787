import { useState } from "react";

const GateAllocation = () => {
    return (
        <div>
            GateAllocation
        </div>
    )
}

export default GateAllocation