import { icons } from "../../../../utils/icons"

const GateHeader = () => {
    return (
        <div className="flex justify-between items-center">
            <div>
                <p className="font-semibold text-lg">Create Rules</p>
                <p className="text-gray-500 text-xs">Set rules for gate assignments and operations</p>
            </div>
            <div>
                <button type="button" className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-black bg-inherit hover:bg-gray-100 rounded-lg underline">
                    History
                </button>

                <button type="button" className="px-3 ml-2 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-gray-800 hover:bg-gray-900 rounded-lg">
                    <icons.PI.PiPlus /><p className="ml-1">Add New Rule</p>
                </button>
            </div>
        </div>
    )
}

export default GateHeader