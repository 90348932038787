import { useState } from "react";
import { icons } from "../../../utils/icons"
import AddEditModal from "./addEditModal"

const ImageList = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <div>
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={handleOpenModal}
                    className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-gray-700 rounded-lg hover:bg-gray-800">
                    <icons.PI.PiPlus className="mr-2" />
                    Add New
                </button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                {Array.from({ length: 12 }).map((_, index) => (
                    <div key={index}>
                        {/* CARD */}
                        <div className="relative max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <a href="/airline-assets">
                                <img className="rounded-lg" src="https://flowbite.com/docs/images/blog/image-1.jpg" alt="" />
                            </a>
                            <div className="absolute right-0 bottom-0 p-5">
                                <a href="/airline-assets" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800">
                                    View
                                    <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>


            {isModalOpen && <AddEditModal onClose={handleCloseModal} />}


        </div>
    )
}

export default ImageList