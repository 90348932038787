import { icons } from "../../../utils/icons"

const Behaviour = () => {
    return (
        <div className="p-2">
            <div className="font-bold border-b">Layering Behaviour</div>
            <div>
                <div className="flex items-center gap-x-2 pt-4">
                    <icons.PI.PiToggleRightFill size={24} className="text-green-400" />
                    <span className="font-bold text-sm">Allocation takes priority</span>
                </div>

                <div className="flex items-center gap-x-2 pt-4">
                    <icons.PI.PiToggleRightFill size={24} className="text-green-400" />
                    <span className="font-bold text-sm">Overlap into subrow</span>
                </div>

                <div className="flex items-center gap-x-2 pt-4">
                    <icons.PI.PiToggleRightFill size={24} className="text-green-400" />
                    <span className="font-bold text-sm">Selected over allocation layer</span>
                </div>
            </div>
        </div>
    )
}

export default Behaviour