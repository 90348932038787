import React from "react"
import { icons } from "../../../utils/icons"

interface ActiveFlight {
    id: number,
    flightNo: string,
    airline: string,
    aircraftReg: string,
    status: string,
    OTE: string,
    STD: string,
    STA: string,
    ETD: string,
    ETA: string,
    ATD: string,
    ATA: string,
    start: Date,
    end: Date,
    color: string,
    row: number,
    type: string,
}
interface FlightDetailProp {
    onClose: () => void,
    activeFlight: any
}
const FlightDetail: React.FC<FlightDetailProp> = ({ onClose, activeFlight }) => {
    return (
        <div className="bg-slate-100 w-72 h-full p-4">
            <div>
                <div className="flex justify-between items-center text-gray-600">
                    <div className="font-semibold text-xs">
                        TURNAROUND FLIGHT DETAILS
                    </div>
                    <button
                        onClick={onClose}>
                        <icons.PI.PiXThin size={15} />
                    </button>
                </div>
            </div>

            <div className="flex mt-6">
                <div className="flex items-center justify-center">
                    <icons.PI.PiAirplaneTiltDuotone size={24} className="text-blue-500" />
                </div>

                <div className="flex flex-col flex-grow ml-2">
                    <div className="flex justify-between items-center" style={{ fontSize: '10px' }}>
                        <p>FLIGHT NUMBER</p>
                        <p>FLIGHT TYPE</p>
                    </div>
                    <div className="flex justify-between items-center text-sm font-bold">
                        <p>{activeFlight?.flightNo}</p>
                        <p className="uppercase">{activeFlight?.type}</p>
                    </div>
                </div>
            </div>


            <div className="flex mt-4">
                <div className="flex flex-row flex-grow ml-2 w-full">
                    <div className="grid grid-cols-2 w-full">
                        <div className="flex flex-col">
                            <p style={{ fontSize: '10px' }}>
                                AIRLINE
                            </p>
                            <div className="flex-grow font-bold text-sm">
                                {activeFlight?.airline}
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <p className="text-end" style={{ fontSize: '10px' }}>
                                AIRCRAFT REG
                            </p>
                            <div className="flex-grow text-end font-bold text-sm">
                                {activeFlight?.aircraftReg}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="flex mt-4">
                <div className="flex items-center justify-center">
                    <icons.PI.PiAirplaneInFlightDuotone className="text-blue-500" size={24} />
                </div>

                <div className="flex flex-col flex-grow ml-2">
                    <div className="flex justify-between items-center" style={{ fontSize: '10px' }}>
                        <p>STATUS</p>
                        <p>OTE</p>
                    </div>
                    <div className="flex justify-between items-center text-sm font-bold">
                        <p>{activeFlight?.status}</p>
                        <p>{activeFlight?.OTE}</p>
                    </div>
                </div>

                <div className="flex items-center justify-center ml-2">
                    <icons.PI.PiNavigationArrowDuotone size={24} className="text-green-500" />
                </div>
            </div>


            <div className="mt-4">
                <div className="font-semibold text-lg">Operational Time Metrics</div>
                <div className="grid grid-cols-2 mt-2">
                    <div className="grid grid-rows-2 grid-flow-col">
                        <p className="font-semibold text-sm">STD</p>
                        <p className="text-xs">0300, 01-04-2024</p>
                    </div>

                    <div className="grid grid-rows-2 grid-flow-col">
                        <p className="text-end font-semibold text-sm">STA</p>
                        <p className="text-end text-xs">0400, 01-04-2024</p>
                    </div>
                </div>


                <div className="grid grid-cols-2 mt-2">
                    <div className="grid grid-rows-2 grid-flow-col">
                        <p className="font-semibold text-sm">ETD</p>
                        <p className="text-xs">0300, 01-04-2024</p>
                    </div>

                    <div className="grid grid-rows-2 grid-flow-col">
                        <p className="text-end font-semibold text-sm">ETA</p>
                        <p className="text-end text-xs">0400, 01-04-2024</p>
                    </div>
                </div>

                <div className="grid grid-cols-2 mt-2">
                    <div className="grid grid-rows-2 grid-flow-col">
                        <p className="font-semibold text-sm">ATD</p>
                        <p className="text-xs">0300, 01-04-2024</p>
                    </div>

                    <div className="grid grid-rows-2 grid-flow-col">
                        <p className="text-end font-semibold text-sm">ATA</p>
                        <p className="text-end text-xs">N/A</p>
                    </div>
                </div>
            </div>


            <div className="mt-4">
                <div className="font-semibold text-lg">Linked Flight</div>
                <div className="ml-4 text-red-500 text-sm"><icons.PI.PiSignOutFill className="inline" size={15} /> <p className="inline">Unlink Linked Flight?</p></div>
                <div className="w-full bg-gray-50 p-2 mt-1 rounded-lg shadow-sm">
                    {/* Top Row */}
                    <div className="grid grid-cols-2 gap-1 text-sm text-gray-500">
                        <div>
                            <p className="uppercase" style={{ fontSize: '10px' }}>Flight Number</p>
                            <p className="text-base font-semibold text-black truncate text-xs">JL902</p>
                        </div>
                        <div>
                            <p className="uppercase text-end" style={{ fontSize: '10px' }}>Flight Type</p>
                            <p className="text-base font-semibold text-black truncate text-end text-xs">Outbound</p>
                        </div>
                        <div>
                            <p className="uppercase" style={{ fontSize: '10px' }}>Airline</p>
                            <p className="text-base font-semibold text-black text-xs">Japanese Airlines (JAL)</p>
                        </div>
                        <div>
                            <p className="uppercase text-end" style={{ fontSize: '10px' }}>Aircraft</p>
                            <p className="text-base font-semibold text-black truncate text-end text-xs">JA789A</p>
                        </div>
                    </div>

                    {/* Middle Row */}
                    <div className="grid grid-cols-2 text-sm text-gray-500">
                        <div className="mt-4">
                            <p className="uppercase text-gray-500" style={{ fontSize: '10px' }}>Status</p>
                            <p className="text-base font-semibold text-black text-xs">Scheduled</p>
                        </div>
                        <div className="mt-4">
                            <p className="uppercase text-gray-500 text-end" style={{ fontSize: '10px' }}>OTE</p>
                            <p className="text-base font-semibold text-black text-end text-xs">N/A</p>
                        </div>
                    </div>

                    {/* Bottom Row */}
                    <div className="grid grid-cols-2 gap-1 mt-4 text-sm text-gray-500">
                        <div>
                            <p className="uppercase" style={{ fontSize: '10px' }}>STD</p>
                            <p className="text-black" style={{ fontSize: '8px' }}>0415, 01-04-2024</p>
                        </div>
                        <div>
                            <p className="uppercase text-end" style={{ fontSize: '10px' }}>ETD</p>
                            <p className="text-black text-end" style={{ fontSize: '8px' }}>0415, 01-04-2024</p>
                        </div>
                        <div>
                            <p className="uppercase" style={{ fontSize: '10px' }}>ATD</p>
                            <p className="text-black" style={{ fontSize: '8px' }}>0300, 01-04-2024</p>
                        </div>
                        <div>
                            <p className="uppercase text-end" style={{ fontSize: '10px' }}>OTE</p>
                            <p className="text-black text-end" style={{ fontSize: '8px' }}>N/A</p>
                        </div>
                        <div>
                            <p className="uppercase" style={{ fontSize: '10px' }}>STA</p>
                            <p className="text-black" style={{ fontSize: '8px' }}>0535, 01-04-2024</p>
                        </div>
                        <div>
                            <p className="uppercase text-end" style={{ fontSize: '10px' }}>ETA</p>
                            <p className="text-black text-end" style={{ fontSize: '8px' }} >0535, 01-04-2024</p>
                        </div>
                        <div>
                            <p className="uppercase" style={{ fontSize: '10px' }}>ATA</p>
                            <p className="text-black" style={{ fontSize: '8px' }}>N/A</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="mt-2">
                <div className="flex items-center mb-2">
                    <input id="default-radio-1" type="radio" value="" name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="default-radio-1" className="ms-2 text-xs font-medium text-gray-900 dark:text-gray-300">Lock Linked Flight</label>
                </div>
                <div className="flex items-center">
                    <input checked id="default-radio-2" type="radio" value="" name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label htmlFor="default-radio-2" className="ms-2 text-xs font-medium text-gray-900 dark:text-gray-300">Allow Linked Flight to be Reassigned</label>
                </div>
            </div>

            <div className="mt-4">
                <div className="flex justify-center gap-x-6">
                    <button type="button" className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-gray-800 rounded-lg hover:bg-gray-900">
                        Apply
                    </button>
                    <button type="button" onClick={onClose} className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-gray-800 rounded-lg hover:bg-gray-900">
                        Cancel
                    </button>
                </div>
            </div>
        </div >
    )
}

export default FlightDetail